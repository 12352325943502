// components/Database.js
import React, { useState } from 'react';
import ProductDatabase from '../Database/ProductDatabase';
import IngredientDatabase from './IngredientForm/IngredientDatabase';
import Navbar from '../Database/Navbar';

const Database = () => {
  const [selectedDatabase, setSelectedDatabase] = useState('products'); // Track selected database

  const handleDatabaseSelect = (dbType) => {
    setSelectedDatabase(dbType);
  };

  return (
    <div>
      <Navbar handleDatabaseSelect={handleDatabaseSelect} />
      <div>
        {selectedDatabase === 'products' ? <ProductDatabase /> : <IngredientDatabase />}
      </div>
    </div>
  );
};

export default Database;
