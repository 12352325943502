import React, { useState } from 'react';
import { FaPlus, FaTrash, FaCog } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS

const ProductDBForm = () => {
    const [ingredients, setIngredients] = useState([{
        ingredient: '',
        itemCode: '',
        composition: '',
        suggestedIngredients: [],
        suggestedItemCodes: []
    }]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [productId, setProductId] = useState('');
    const [productName, setProductName] = useState('');
    const navigate = useNavigate();

    const addIngredient = () => {
        setIngredients([...ingredients, {
            ingredient: '',
            itemCode: '',
            composition: '',
            suggestedIngredients: [],
            suggestedItemCodes: []
        }]);
    };

    const handleIngredientChange = (index, e) => {
        const value = e.target.value;
        const values = [...ingredients];
        values[index][e.target.name] = value; // Update the item code or composition input

        // Check if the field being changed is 'itemCode'
        if (e.target.name === 'itemCode') {
            if (value.length > 2) {
                // Fetch suggested item codes for the specific ingredient row
                fetch(`${process.env.REACT_APP_BASE_URI}/elixir/fetch_ingredient`, {
                    method: 'GET', headers: {
                        Accept: 'application/json',
                    },
                })
                    .then((response) => response.json())
                    .then((data) => {
                        const ingredientsList = data.data || [];
                        const filteredSuggestions = ingredientsList
                            .map((ingredient) => ({
                                name: ingredient.ing_name, itemCode: ingredient.ing_item_code,
                            }))
                            .filter((option) => option.itemCode && option.itemCode.toLowerCase().includes(value.toLowerCase()));
                        values[index].suggestedItemCodes = filteredSuggestions;
                        setIngredients([...values]);
                    })
                    .catch((error) => console.error('Error fetching suggested item codes:', error));
            } else {
                // If itemCode is cleared, set ingredient to empty
                values[index].ingredient = ''; // Clear the ingredient name
                values[index].suggestedItemCodes = [];
                setIngredients([...values]);
            }
        } else {
            // If the composition field is being edited, simply update its value
            values[index][e.target.name] = value;
            setIngredients([...values]);
        }
    };

    const handleSuggestionSelect = (index, suggestion, field) => {
        const values = [...ingredients];
        if (field === 'itemCode') {
            // Set the itemCode and the corresponding ingredient name
            values[index].itemCode = suggestion.itemCode;
            values[index].ingredient = suggestion.name; // Automatically set ingredient name
            values[index].suggestedItemCodes = [];
        } else {
            // This block may not be needed anymore since ingredient is non-editable
            values[index][field] = suggestion;
            values[index].suggestedIngredients = [];
        }
        setIngredients(values);
    };

    const removeIngredient = (index) => {
        const values = [...ingredients];
        values.splice(index, 1);
        setIngredients(values);
    };

    const handleUpload = async (event) => {
        event.preventDefault();

        const hasEmptyIngredient = ingredients.some(ingredient => ingredient.ingredient.trim() === '');
        if (hasEmptyIngredient) {
            toast.error('All ingredient names must be filled out.');
            return; // Prevent form submission
        }

        const ingredientsArray = ingredients.map((ingredient) => ({
            ing_item_code: ingredient.itemCode.toUpperCase(),
            ing_name: ingredient.ingredient,
            per_composition: parseFloat(ingredient.composition.replace('%', '')),
        }));

        const data = {
            data: {
                product_id: productId,
                ingredients: ingredientsArray,
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
            }, data1: {
                product_id: productId,
                product_name: productName,
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
            },
        };

        const isValidIngredients = ingredientsArray.every((ingredient) => /^[A-Z0-9]+$/.test(ingredient.ing_item_code) && !isNaN(ingredient.per_composition) && ingredient.per_composition >= 0 && ingredient.per_composition <= 100);

        if (!isValidIngredients) {
            alert('Invalid ingredient details. Please check item codes and compositions.');
            return;
        }

        try {
            // New PUT request to the single API endpoint
            const putResponse = await fetch(`${process.env.REACT_APP_BASE_URI}/elixir/product_composition`, {
                method: 'PUT', headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json',
                }, body: JSON.stringify(data),
            });

            if (!putResponse.ok) {
                const errorData = await putResponse.json();
                toast.error(`Error in PUT request: ${errorData.message || 'Unknown error'}`);
                throw new Error(`HTTP error! status: ${putResponse.status}, ${JSON.stringify(errorData)}`);
            }

            toast.success('Product composition updated successfully!');

            setTimeout(() => {
                navigate('/database');
            }, 3000);
        } catch (error) {
            console.error('Error uploading data:', error);
        }
    };

    const handleDatabase = () => {
        navigate('/database');
    };

    return (<div className="bg-gray-900 min-h-screen text-gray-200 p-8">
        <ToastContainer
            theme="dark" />
        <nav className="navbar bg-gray-800 text-white p-4 flex justify-between">
            <h1 className="text-2xl font-bold">Database</h1>
            <div className="relative">
                <button
                    onClick={() => setShowDropdown(!showDropdown)}
                    className="text-white font-bold py-2 px-4 rounded"
                    title="Settings"
                >
                    <FaCog />
                </button>
                {showDropdown && (
                    <ul className="absolute right-0 mt-2 w-48 bg-gray-700 text-white rounded shadow-lg z-20">
                        <li onClick={handleDatabase} className="py-2 px-4 hover:bg-gray-600 cursor-pointer">
                            Database
                        </li>
                    </ul>)}
            </div>
        </nav>

        <div className="p-8 bg-gray-800 rounded-lg mt-6">
            <h2 className="text-xl font-bold mb-4">Product Form</h2>
            <form className="space-y-6" onSubmit={handleUpload}>
                <div className="grid grid-cols-2 gap-6">
                    <div className="flex flex-col">
                        <label className="font-semibold">Product ID</label>
                        <input
                            type="text"
                            value={productId}
                            onChange={(e) => setProductId(e.target.value)}
                            className="border p-2 rounded bg-gray-700"
                            required
                        />
                    </div>
                    <div className="flex flex-col">
                        <label className="font-semibold">Product Name</label>
                        <input
                            type="text"
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                            className="border p-2 rounded bg-gray-700"
                            required
                        />
                    </div>
                </div>

                {ingredients.map((ingredient, index) => (
                    <div key={index} className="grid grid-cols-4 space-x-6 items-center">
                        {/* Item Code comes before Ingredient */}
                        <div className="flex flex-col relative">
                            <label className="font-semibold">Item Code</label>
                            <input
                                type="text"
                                name="itemCode"
                                value={ingredient.itemCode}
                                onChange={(e) => handleIngredientChange(index, e)}
                                className="border p-2 rounded bg-gray-700"
                                required
                            />
                            {ingredient.suggestedItemCodes.length > 0 && (
                                <ul className="bg-gray-800 border border-gray-700 mt-1 max-h-48 overflow-y-auto absolute top-16 w-full z-30">
                                    {ingredient.suggestedItemCodes.map((suggested, idx) => (<li
                                        key={idx}
                                        className="p-2 hover:bg-gray-600 cursor-pointer"
                                        onClick={() => handleSuggestionSelect(index, suggested, 'itemCode')}
                                    >
                                        {suggested.itemCode} - {suggested.name}
                                    </li>))}
                                </ul>)}
                        </div>

                        <div className="flex flex-col">
                            <label className="font-semibold">Ingredient</label>
                            <input
                                type="text"
                                name="ingredient"
                                value={ingredient.ingredient}
                                onChange={(e) => handleIngredientChange(index, e)}
                                className="border p-2 rounded bg-gray-700"
                                required
                                readOnly // Make this field read-only to prevent editing
                            />
                        </div>

                        <div className="flex flex-col">
                            <label className="font-semibold">Composition (%)</label>
                            <input
                                type="text"
                                name="composition"
                                value={ingredient.composition}
                                onChange={(e) => handleIngredientChange(index, e)}
                                className="border p-2 rounded bg-gray-700"
                                required
                            />
                        </div>

                        <div className="flex items-center">
                            <button
                                type="button"
                                onClick={() => removeIngredient(index)}
                                className="mt-6 bg-red-500 text-white rounded p-2"
                            >
                                <FaTrash />
                            </button>
                        </div>
                    </div>))}

                <button
                    type="button"
                    onClick={addIngredient}
                    className="bg-blue-500 hover:bg-blue-700 text-white rounded p-2 flex items-center gap-2 font-semibold"
                >
                    <FaPlus />
                    Add Ingredient
                </button>

                <button
                    type="submit"
                    className="bg-green-500 text-white rounded p-2 w-full"
                >
                    Submit
                </button>
            </form>
        </div>
    </div>);
};

export default ProductDBForm;
