// src/Components/PrivateRoute.jsx
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { COGNITO_CONFIG } from './Services/cognitoconfig';
import { jwtDecode } from 'jwt-decode';

const PrivateRoute = ({ component: Component, requiredRole }) => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);

  const poolData = {
    UserPoolId: COGNITO_CONFIG.UserPoolId,
    ClientId: COGNITO_CONFIG.ClientId
  };

  const userPool = new CognitoUserPool(poolData);
  const user = userPool.getCurrentUser();

  useEffect(() => {
    if (user) {
      user.getSession((err, session) => {
        if (err) {
          console.error('Error getting session', err);
          setLoading(false);
          setHasAccess(false);
        } else {
          const accessToken = session.getAccessToken().getJwtToken();
          const decodedToken = jwtDecode(accessToken);
          const userGroups = decodedToken['cognito:groups'] || [];

          // Grant access to all routes if user is an admin
          if (userGroups.includes('ADMIN')) {
            setHasAccess(true);
          } else if (requiredRole && userGroups.includes(requiredRole)) {
            setHasAccess(true);
          } else if (!requiredRole) {
            setHasAccess(true);
          } else {
            setHasAccess(false);
          }
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
      setHasAccess(false);
    }
  }, [user, requiredRole]);

  if (loading) {
    return <div>Loading...</div>; // Show a loading spinner or message
  }

  if (navigate.state && navigate.state.requiredRole === 'USER') {
    setHasAccess(true);
  }

  return hasAccess ? <Component /> : <Navigate to="/" />;
};

export default PrivateRoute;