import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

const Productitem = () => {
  const { product_id } = useParams(); // Get product_id from URL params
  const [product, setProduct] = useState(null);
  const [ingredients, setIngredients] = useState([]); // To store the list of ingredients
  const [error, setError] = useState(null);
  const [editedIngredient, setEditedIngredient] = useState(null); // For editing
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URI}/elixir/fetch_items/${product_id}`,
          {
            method: "GET",
            headers: {
              accept: "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Product not found");
        }

        const data = await response.json();
        setProduct(data.data[0]); // Set the main product details
        setIngredients(data.data); // Assuming the API returns all ingredients for this product
      } catch (error) {
        setError(error.message);
      }
    };

    fetchProduct();
  }, [product_id]);

  const handlePatch = async (ingredient) => {
    try {
      const payload = {
        product_id,
        ingredients: [
          {
            ing_item_code: ingredient.ing_item_code,
            ing_name: ingredient.ing_name,
            per_composition: ingredient.per_composition,
          },
        ],
        created_at: product.created_at,
        updated_at: new Date().toISOString(),
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URI}/elixir/item/${product_id}?ing_item_code=${ingredient.ing_item_code}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update the ingredient");
      }

      alert("Ingredient updated successfully!");
      setEditedIngredient(null); // Reset edit mode
      const updatedIngredients = ingredients.map((ing) =>
        ing.ing_item_code === ingredient.ing_item_code ? ingredient : ing
      );
      setIngredients(updatedIngredients);
    } catch (error) {
      setError("Failed to update the ingredient");
    }
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this product?"
    );
    if (confirmDelete) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URI}/elixir/product/${product_id}`,
          {
            method: "DELETE",
            headers: {
              accept: "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to delete the product");
        }

        alert("Product deleted successfully");
        navigate("/database"); // Redirect to the database page after deletion
      } catch (error) {
        setError("Failed to delete the product");
      }
    }
  };

  if (error) {
    return <p className="text-red-500 text-center">{error}</p>;
  }

  if (!product) {
    return <p className="text-white text-center">Loading...</p>;
  }

  return (
    <div className="min-h-screen bg-slate-700 flex flex-col items-center">
      <button
        onClick={() => navigate("/database")}
        className="absolute top-4 left-4 bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
      >
        Back
      </button>

      <div className="flex justify-center items-center p-4 w-full my-auto">
        <div className="max-w-4xl w-full bg-slate-800 rounded-lg text-white shadow-lg p-6">
          <h1 className="text-2xl font-bold mb-4">{product.product_name}</h1>
          <p className="mb-2">
            <span className="font-semibold">Product ID: </span>
            {product.product_id}
          </p>

          <h2 className="text-xl font-bold mt-6 mb-2">Ingredient Details</h2>
          <table className="w-full text-white text-center">
            <thead>
              <tr>
                <th className="border-b border-slate-600 px-4 py-2">
                  Ingredient Name
                </th>
                <th className="border-b border-slate-600 px-4 py-2">
                  Item Code
                </th>
                <th className="border-b border-slate-600 px-4 py-2">
                  Per Composition
                </th>
                <th className="border-b border-slate-600 px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {ingredients.map((ingredient, index) => (
                <tr key={index}>
                  <td className="border-b border-slate-600 px-4 py-2">
                    {ingredient.ing_name}
                  </td>
                  <td className="border-b border-slate-600 px-4 py-2">
                    {ingredient.ing_item_code}
                  </td>
                  <td className="border-b border-slate-600 px-4 py-2">
                    {editedIngredient?.ing_item_code ===
                      ingredient.ing_item_code ? (
                      <input
                        type="text"
                        value={editedIngredient.per_composition}
                        onChange={(e) =>
                          setEditedIngredient({
                            ...editedIngredient,
                            per_composition: e.target.value,
                          })
                        }
                        className="bg-gray-600 text-center text-white rounded px-3 py-2 border border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    ) : (
                      ingredient.per_composition ?? "N/A"
                    )}
                  </td>
                  <td className="border-b border-slate-600 px-4 py-2">
                    {editedIngredient?.ing_item_code ===
                      ingredient.ing_item_code ? (
                      <>
                        <button
                          onClick={() => handlePatch(editedIngredient)}
                          className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded"
                        >
                          Save
                        </button>
                        <button
                          onClick={() => setEditedIngredient(null)}
                          className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-1 px-2 rounded ml-2"
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <button
                        onClick={() => setEditedIngredient(ingredient)}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded"
                      >
                        Edit
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="mt-6 flex justify-between">
            <button
              onClick={handleDelete}
              className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Productitem;
