// UserLogin.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { COGNITO_CONFIG } from "./cognitoconfig";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { jwtDecode } from "jwt-decode";
import background from "../../img/background3.jpg";

const UserLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loadingState, setLoadingState] = useState("idle");
  const [isTempPassword, setIsTempPassword] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);

  const navigate = useNavigate();

  const poolData = {
    UserPoolId: COGNITO_CONFIG.UserPoolId,
    ClientId: COGNITO_CONFIG.ClientId,
  };
  const userPool = new CognitoUserPool(poolData);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoadingState("loading");

    try {
      const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });

      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          console.log("Login successful:", result);
          const accessToken = result.getAccessToken().getJwtToken();
          const decodedToken = jwtDecode(accessToken);
          const userGroups = decodedToken["cognito:groups"] || [];

          if (userGroups.includes("ADMIN")) {
            navigate("/database");
          } else if (userGroups.includes("USER")) {
            navigate("/download");
          } else {
            alert("Your account is not authorized to access this application.");
          }
          setLoadingState("idle");
        },
        onFailure: (err) => {
          console.error("Error signing in:", err);
          alert(`Failed to sign in: ${err.message}`);
          setLoadingState("idle");
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          console.log("New password required");
          setIsTempPassword(true);
          setCognitoUser(cognitoUser);
          setLoadingState("idle");
        },
      });
    } catch (error) {
      console.error("Error signing in:", error);
      alert(`Failed to sign in: ${error.message}`);
      setLoadingState("idle");
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setLoadingState("loading");

    try {
      const userAttributes = {
        // Add other required attributes if needed
      };
      cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
        onSuccess: (result) => {
          console.log("Password changed successfully:", result);
          navigate("/download"); // Redirect to the download page after changing the password
        },
        onFailure: (err) => {
          console.error("Failed to change password:", err);
          alert(`Failed to change password: ${err.message}`);
          setLoadingState("idle");
        },
      });
    } catch (error) {
      console.error("Error changing password:", error);
      alert(`Failed to change password: ${error.message}`);
      setLoadingState("idle");
    }
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div
        className="bg-black p-10 space-y-6 max-w-lg w-full rounded-lg shadow-lg"
        style={{ background: "rgba(0, 0, 0, 0.7)" }}
      >
        <h1 className="text-center text-white text-2xl font-bold">
          {isTempPassword ? "Change Password" : "Login"}
        </h1>
        <form onSubmit={isTempPassword ? handleChangePassword : handleLogin}>
          <div className="relative">
            <input
              type="text"
              className="border-0 border-b-2 w-full border-gray-500 bg-transparent placeholder:text-gray-500 focus:outline-none focus:border-gray-300 text-white py-3 px-4 rounded-md"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="relative mt-4">
            <input
              type={showPassword ? "text" : "password"}
              className="border-0 border-b-2 w-full border-gray-500 bg-transparent placeholder:text-gray-500 focus:outline-none focus:border-gray-300 text-white py-3 px-4 rounded-md pr-12"
              placeholder="Password"
              value={isTempPassword ? newPassword : password}
              onChange={(e) =>
                isTempPassword
                  ? setNewPassword(e.target.value)
                  : setPassword(e.target.value)
              }
              required
            />
            <span
              className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <FaEyeSlash className="text-white" />
              ) : (
                <FaEye className="text-white" />
              )}
            </span>
          </div>
          <button
            type="submit"
            className="mt-6 text-white border border-white p-3 w-full rounded-md hover:bg-white hover:text-black transition ease-in-out duration-300"
            disabled={loadingState === "loading"}
          >
            {loadingState === "loading"
              ? isTempPassword
                ? "Changing..."
                : "Logging in..."
              : isTempPassword
                ? "Change Password"
                : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UserLogin;
