import React from 'react';
import notfound from "../../img/tow.svg";

const NotFoundPage = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-800 text-white">
            <div className="text-center">
                <h1 className="text-9xl font-extrabold text-gray-300">404</h1>
                <h2 className="text-4xl font-bold mt-4">Page Not Found</h2>
                <p className="text-lg text-gray-300 mt-2">
                    Oops! The page you're looking for doesn't exist.
                </p>
                <div className="mt-6">
                    <a
                        href="/"
                        className="px-6 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-lg shadow-lg transition duration-300 ease-in-out"
                    >
                        Go Back to Homepage
                    </a>
                </div>
            </div>
            <div className="mt-8">
                <img
                    src={notfound}
                    alt="Page not found illustration"
                    className="w-full max-w-sm h-64 rounded-lg shadow-md"
                />
            </div>
        </div>
    );
};

export default NotFoundPage;
