import React, { useState, useCallback } from 'react';
import { FaCog } from 'react-icons/fa';
import Declaration from './Declaration'; // Your combined component
import Attributes from './Attributes'; // Your combined component
import AllergenSelection from './Allergens'; // New component
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const IngredientDBForm = () => {
  const [currentStep, setCurrentStep] = useState(1); // To track the pagination steps
  const [dropdownOpen, setDropdownOpen] = useState(false); // State for dropdown visibility
  const navigate = useNavigate();

  const [ingredients, setIngredients] = useState([{ ingredientName: '', itemCode: '', vendorName: '', rmCode: '' }]);

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const [columnValues, setColumnValues] = useState({
    ing_item_code: '',
    rm_code: '',
    vegetarian: '',
    vegan: '',
    non_gmo: '',
    classification: '',
    gluten_status: '',
    bse_tse: '',
    declared_allergen: '',
    allergen_fermentation: '',
    residual_solvent: '',
    wada_compliance: '',
    eto_treated: '',
    irradiated: '',
    sewage_sludge_treated: '',
    pesticide: '',
    aflatoxin: '',
    preservative: '',
    antibiotic: '',
    gras: '',
    prop65_complaint: '',
  });

  const [attributeDetails, setAttributeDetails] = useState({
    casNumber: '', ecNumber: '', ingredientType: '', sourceType: '', source: '', country: '',
  });

  const [allergens, setAllergens] = useState({
    wheat: '',
    eggs: '',
    crustaceans_shell_fish: '',
    fish: '',
    milk: '',
    tree_nuts: '',
    peanuts: '',
    soy: '',
    sesame_seeds: '',
    celery: '',
    barley_oats_rye_spelt: '',
    orange_kiwi_peaches_apples: '',
    mushrooms: '',
    mustard: '',
    lupin: '',
    mulluscs: '',
    sulfur: ''
  });

  // Handle ingredient field changes
  const handleIngredientChange = (index, event) => {
    const values = [...ingredients];
    values[index][event.target.name] = event.target.value;
    setIngredients(values);
  };

  // Handle changes for column values
  const handleColumnValueChange = (event) => {
    setColumnValues((prevValues) => ({
      ...prevValues, [event.target.name]: event.target.value,
    }));
  };

  // Handle changes for attribute details
  const handleAttributeDetailsChange = useCallback((event) => {
    setAttributeDetails((prevDetails) => ({
      ...prevDetails, [event.target.name]: event.target.value,
    }));
  }, []);

  // Handle allergen dropdown changes
  const handleAllergenChange = (event) => {
    setAllergens((prevAllergens) => ({
      ...prevAllergens, [event.target.name]: event.target.value,
    }));
  };

  // Handle form submission
  const handleSubmit = async () => {
    // Define the payloads to match the cURL request format
    const payload = {
      data: {
        ing_item_code: ingredients[0].itemCode,
        ing_name: ingredients[0].ingredientName,
        vendor: ingredients[0].vendorName,
        rm_code: ingredients[0].rmCode,
        cas_num: attributeDetails.casNumber,
        ec_num: attributeDetails.ecNumber,
        ing_type: attributeDetails.ingredientType,
        source_type: attributeDetails.sourceType,
        source: attributeDetails.source,
        country_origin: attributeDetails.country,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      },
      data1: {
        ing_item_code: columnValues.ing_item_code,
        rm_code: columnValues.rm_code,
        vegetarian: columnValues.vegetarian,
        vegan: columnValues.vegan,
        non_gmo: columnValues.non_gmo,
        classification: columnValues.classification,
        gluten_status: columnValues.gluten_status,
        bse_tse: columnValues.bse_tse,
        declared_allergen: columnValues.declared_allergen,
        wheat: allergens.wheat,
        eggs: allergens.eggs,
        crustaceans_shell_fish: allergens.crustaceans_shell_fish,
        fish: allergens.fish,
        milk: allergens.milk,
        tree_nuts: allergens.tree_nuts,
        peanuts: allergens.peanuts,
        soy: allergens.soy,
        sesame_seeds: allergens.sesame_seeds,
        celery: allergens.celery,
        barley_oats_rye_spelt: allergens.barley_oats_rye_spelt,
        orange_kiwi_peaches_apples: allergens.orange_kiwi_peaches_apples,
        mushrooms: allergens.mushrooms,
        mustard: allergens.mustard,
        lupin: allergens.lupin,
        mulluscs: allergens.mulluscs,
        sulfur: allergens.sulfur,
        allergen_fermentation: columnValues.allergen_fermentation,
        residual_solvent: columnValues.residual_solvent,
        wada_compliance: columnValues.wada_compliance,
        eto_treated: columnValues.eto_treated,
        irradiated: columnValues.irradiated,
        sewage_sludge_treated: columnValues.sewage_sludge_treated,
        pesticide: columnValues.pesticide,
        aflatoxin: columnValues.aflatoxin,
        preservative: columnValues.preservative,
        antibiotic: columnValues.antibiotic,
        gras: columnValues.gras,
        prop65_complaint: columnValues.prop65_complaint,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      },
    };

    try {
      // Send combined data payload
      await axios.put(`${process.env.REACT_APP_BASE_URI}/elixir/raw_material`, payload, {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      toast.success('Data submitted successfully!');
      setTimeout(() => {
        navigate('/database');
      }, 3000);
    } catch (error) {
      console.error('Error submitting data:', error);
      toast.error('Failed to submit data.');
    }
  };

  // Pagination: Go to the next step
  const nextStep = () => {
    if (currentStep < 4) {
      if (currentStep === 1) {
        let isValid = true;

        // Assuming multiple ingredients, check all for valid itemCode and rmCode
        ingredients.forEach((ingredient) => {
          if (!ingredient.itemCode || !ingredient.rmCode) {
            isValid = false;
            toast.error("Item Code and RM Code must be filled for all ingredients!");
          }
        });

        if (!isValid) return; // Stop if validation fails

        // Collect itemCode and rmCode for all ingredients if valid
        const itemCodes = ingredients.map(ingredient => ingredient.itemCode).join(", ");
        const rmCodes = ingredients.map(ingredient => ingredient.rmCode).join(", ");

        // Set columnValues with the collected codes
        setColumnValues((prevValues) => ({
          ...prevValues,
          ing_item_code: itemCodes,
          rm_code: rmCodes
        }));
      }

      // Move to the next step
      setCurrentStep(currentStep + 1);
    }
  };


  // Pagination: Go to the previous step
  const previousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handledb = () => {
    navigate('/database');
  };

  return (<div className="bg-gray-900 min-h-screen text-gray-200 p-8">
    <nav className="navbar bg-gray-800 text-white p-4 flex justify-between">
      <h1 className="text-2xl font-bold">Ingredient Database</h1>
      <div className="relative">
        <button
          className="text-white font-bold py-2 px-4 rounded"
          title="Settings"
          onClick={toggleDropdown}
        >
          <FaCog />
        </button>
        {dropdownOpen && (
          <div className="absolute right-0 mt-2 bg-gray-900 text-white rounded shadow-lg p-2 w-48">
            <ul>
              <li
                onClick={handledb}
                className="py-1 cursor-pointer hover:bg-gray-700">
                Database
              </li>
            </ul>
          </div>)}
      </div>
    </nav>

    <div className="p-8 bg-gray-800 rounded-lg mt-6 space-y-6">
      <h2 className="text-xl font-bold mb-4">Step {currentStep}</h2>

      {/* Step 1: Ingredient Information */}
      {currentStep === 1 && (<form className="space-y-6">
        {ingredients.map((ingredient, index) => (
          <div key={index} className="flex items-center space-x-4 mb-2">
            <div className="flex flex-col w-1/4">
              <label className="font-semibold">Ingredient Name</label>
              <input
                type="text"
                name="ingredientName"
                value={ingredient.ingredientName}
                onChange={(event) => handleIngredientChange(index, event)}
                className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
                placeholder="Enter Ingredient Name"
                required
              />
            </div>
            <div className="flex flex-col w-1/4">
              <label className="font-semibold">Item Code</label>
              <input
                type="text"
                name="itemCode"
                value={ingredient.itemCode}
                onChange={(event) => handleIngredientChange(index, event)}
                className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
                placeholder="Enter Item Code"
                required
              />
            </div>
            <div className="flex flex-col w-1/4">
              <label className="font-semibold">Vendor Name</label>
              <input
                type="text"
                name="vendorName"
                value={ingredient.vendorName}
                onChange={(event) => handleIngredientChange(index, event)}
                className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
                placeholder="Enter Vendor Name"
                required
              />
            </div>
            <div className="flex flex-col w-1/4">
              <label className="font-semibold">RM Code</label>
              <input
                type="text"
                name="rmCode"
                value={ingredient.rmCode}
                onChange={(event) => handleIngredientChange(index, event)}
                className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
                placeholder="RM-XXX-XXX"
                required
              />
            </div>
          </div>))}
      </form>)}

      {/* Step 2: Declaration */}
      {currentStep === 2 && (<Declaration
        columnValues={columnValues}
        handleColumnValueChange={handleColumnValueChange}
        className="w-1/2"
      />)}

      {/* Step 3: Allergen Selection */}
      {currentStep === 3 && (<AllergenSelection
        allergens={allergens}
        handleAllergenChange={handleAllergenChange}
      />)}

      {/* Step 4: Attributes */}
      {currentStep === 4 && (<Attributes
        attributeDetails={attributeDetails}
        handleAttributeDetailsChange={handleAttributeDetailsChange}
        className="w-1/2"
      />)}

      {/* Pagination Controls */}
      <div className="flex justify-between mt-4">
        {currentStep > 1 && (<button
          onClick={previousStep}
          className="bg-gray-700 text-white px-4 py-2 rounded"
        >
          Previous
        </button>)}
        {currentStep < 4 && (<button
          onClick={nextStep}
          className="bg-blue-600 text-white px-4 py-2 rounded"
        >
          Next
        </button>)}
        {currentStep === 4 && (<button
          onClick={handleSubmit}
          className="bg-green-600 text-white px-4 py-2 rounded"
        >
          Submit
        </button>)}
      </div>
    </div>
    <ToastContainer theme='dark' />
  </div>);
};

export default IngredientDBForm;
