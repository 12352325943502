import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const IngredientDatabase = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [ingredients, setIngredients] = useState([]);
    const [filteredIngredients, setFilteredIngredients] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const navigate = useNavigate();

    // Function to fetch ingredients from the API
    const fetchIngredients = async () => {
        try {
            setLoading(true); // Start loading
            const response = await fetch(`${process.env.REACT_APP_BASE_URI}/elixir/fetch_ingredient`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            });
            const { data } = await response.json();

            if (Array.isArray(data)) {
                setIngredients(data);
                setFilteredIngredients(data);
                setError(null); // Clear any previous error
            } else {
                setError('Data format error.');
            }
        } catch (error) {
            setError('Failed to fetch ingredients.');
        } finally {
            setLoading(false); // Stop loading
        }
    };

    // Fetch ingredients when the component mounts
    useEffect(() => {
        fetchIngredients();
    }, []);

    // Search functionality
    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);

        const filtered = ingredients.filter((ingredient) => {
            return (
                ingredient.ing_name.toLowerCase().includes(term) ||
                ingredient.ing_item_code.toLowerCase().includes(term) ||
                ingredient.vendor.toLowerCase().includes(term) ||
                ingredient.rm_code.toLowerCase().includes(term)
            );
        });

        setFilteredIngredients(filtered);
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A'; // Return 'N/A' if the date is null or undefined
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'N/A'; // Return 'N/A' if the date is invalid
        return date.toLocaleDateString('en-GB'); // Formats to DD/MM/YYYY
    };


    // Navigate to IngredientItem component when clicking a row
    const handleRowClick = (itemCode) => {
        navigate(`/ingredient/${itemCode}`);
    };

    return (
        <div className='min-h-screen bg-slate-700'>
            <div className="flex justify-between items-center p-4">
                {/* Search Bar with Icon and Add Ingredient Button on the same line */}
                <div className="flex items-center space-x-4">
                    <div className="relative">
                        <FaSearch className="absolute left-3 top-3 text-white" />
                        <input
                            type="text"
                            placeholder="Search ingredients..."
                            value={searchTerm}
                            onChange={handleSearch}
                            className="pl-10 bg-gray-900 text-white rounded px-4 py-2"
                        />
                    </div>
                </div>

                {/* Add Ingredient Button */}
                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => navigate('/ingredientdb')}
                >
                    + Add Ingredient
                </button>
            </div>

            {loading ? (
                <div className="flex justify-center items-center py-10">
                    <div className="spinner"></div>
                </div>
            ) : error ? (
                <div className="text-center text-red-500">{error}</div>
            ) : (
                <div className="p-4 overflow-x-auto">
                    <table className="text-white w-full border-separate border-spacing-0">
                        <thead className="bg-gray-900">
                            <tr>
                                <th className="px-4 py-2">Ingredient Name</th>
                                <th className="px-4 py-2">Item Code</th>
                                <th className="px-4 py-2">Vendor Name</th>
                                <th className="px-4 py-2">RM Code</th>
                                <th className="px-4 py-2">Created At</th>
                                <th className="px-4 py-2">Updated At</th>
                                {/* New Date Column */}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredIngredients.map((ingredient) => (
                                <tr
                                    key={ingredient.id}
                                    className="bg-gray-800 hover:bg-gray-700 text-center cursor-pointer"
                                    onClick={() => handleRowClick(ingredient.ing_item_code)}  // Redirect to IngredientItem
                                >
                                    <td className="px-4 py-2">{ingredient.ing_name}</td>
                                    <td className="px-4 py-2">{ingredient.ing_item_code}</td>
                                    <td className="px-4 py-2">{ingredient.vendor}</td>
                                    <td className="px-4 py-2">{ingredient.rm_code}</td>
                                    <td className="px-4 py-2">{formatDate(ingredient.created_at)}</td>
                                    <td className="px-4 py-2">{formatDate(ingredient.updated_at)}</td>
                                    {/* Display unformatted date */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default IngredientDatabase;
