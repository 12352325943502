import React from "react";

const Declaration = ({ columnValues, handleColumnValueChange }) => {
  return (
    <div className="bg-gray-800 p-6 rounded-lg w-full space-y-4">
      <h3 className="text-lg font-semibold text-gray-200 mb-4">
        Declaration Information
      </h3>

      <div className="grid grid-cols-2 gap-4">
        {/* Ingredient Item Code */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-300">
            Ingredient Item Code
          </label>
          <input
            type="text"
            name="ing_item_code"
            value={columnValues.ing_item_code}
            onChange={handleColumnValueChange}
            className="border border-gray-600 rounded px-4 py-2 bg-gray-800 text-gray-400"
            placeholder="Enter Ingredient Item Code"
            readOnly
            disabled
          />
        </div>

        {/* RM Code */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-300">RM Code</label>
          <input
            type="text"
            name="rm_code"
            value={columnValues.rm_code}
            onChange={handleColumnValueChange}
            className="border border-gray-600 rounded px-4 py-2 bg-gray-800 text-gray-400"
            placeholder="Enter RM Code"
            readOnly
            disabled
          />
        </div>

        {/* Vegetarian */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-300">Vegetarian</label>
          <select
            name="vegetarian"
            value={columnValues.vegetarian}
            onChange={handleColumnValueChange}
            className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
          >
            <option value="">Select Vegetarian Status</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* Vegan */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-300">Vegan</label>
          <select
            name="vegan"
            value={columnValues.vegan}
            onChange={handleColumnValueChange}
            className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
          >
            <option value="">Select Vegan Status</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* Non-GMO */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-300">Non-GMO</label>
          <select
            name="non_gmo"
            value={columnValues.non_gmo}
            onChange={handleColumnValueChange}
            className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
          >
            <option value="">Select Non-GMO Status</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* Classification */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-300">Classification</label>
          <select
            name="classification"
            value={columnValues.classification}
            onChange={handleColumnValueChange}
            className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
          >
            <option value="">Select Non-Gmo Classification</option>
            <option value="Probiotics">Probiotics</option>
            <option value="Animal-Derived">Animal-Derived</option>
            <option value="Bromelain">Bromelain</option>
            <option value="Microbial Enzymes">Microbial Enzymes</option>
            <option value="Papain">Papain</option>
            <option value="Protein">Protein</option>
            <option value="Other">Other</option>
          </select>
        </div>

        {/* Gluten Status */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-300">Gluten Free</label>
          <select
            name="gluten_status"
            value={columnValues.gluten_status}
            onChange={handleColumnValueChange}
            className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
          >
            <option value="">Select Gluten Status</option>
            <option value="Gluten free">Gluten free</option>
            <option value="Gluten containing">Gluten containing</option>
          </select>
        </div>

        {/* BSE/TSE */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-300">BSE/TSE Free</label>
          <select
            name="bse_tse"
            value={columnValues.bse_tse}
            onChange={handleColumnValueChange}
            className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
          >
            <option value="">Select BSE/TSE Status</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* Declared Allergen */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-300">
            Declared Allergen
          </label>
          <select
            type="text"
            name="declared_allergen"
            value={columnValues.declared_allergen}
            onChange={handleColumnValueChange}
            className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
            placeholder="Enter Declared Allergen"
            required
          >
            <option value="">Select Declared Allergen Status</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* Allergen Fermentation */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-300">
            Allergen Fermentation
          </label>
          <select
            type="text"
            name="allergen_fermentation"
            value={columnValues.allergen_fermentation}
            onChange={handleColumnValueChange}
            className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
            placeholder="Enter Allergen Fermentation"
          >
            <option value="">Select Allergen Fermentation Status</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* Residual Solvent */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-300">
            Residual Solvent
          </label>
          <select
            type="text"
            name="residual_solvent"
            value={columnValues.residual_solvent}
            onChange={handleColumnValueChange}
            className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
            placeholder="Enter Residual Solvent"
          >
            <option value="">Select Residual Solvent Status</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* WADA Compliance */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-300">WADA Compliance</label>
          <select
            name="wada_compliance"
            value={columnValues.wada_compliance}
            onChange={handleColumnValueChange}
            className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
          >
            <option value="">Select WADA Compliance Status</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* ETO Treated */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-300">ETO Treated</label>
          <select
            name="eto_treated"
            value={columnValues.eto_treated}
            onChange={handleColumnValueChange}
            className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
          >
            <option value="">Select ETO Treated Status</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* Irradiated */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-300">Irradiated</label>
          <select
            name="irradiated"
            value={columnValues.irradiated}
            onChange={handleColumnValueChange}
            className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
          >
            <option value="">Select Irradiated Status</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* Sewage Sludge Treated */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-300">
            Sewage Sludge Treated
          </label>
          <select
            name="sewage_sludge_treated"
            value={columnValues.sewage_sludge_treated}
            onChange={handleColumnValueChange}
            className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
          >
            <option value="">Select Sewage Sludge Treated Status</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* Pesticide */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-300">Pesticide</label>
          <select
            name="pesticide"
            value={columnValues.pesticide}
            onChange={handleColumnValueChange}
            className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
          >
            <option value="">Select Pesticide Status</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* Aflatoxin */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-300">Aflatoxin</label>
          <select
            name="aflatoxin"
            value={columnValues.aflatoxin}
            onChange={handleColumnValueChange}
            className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
          >
            <option value="">Select Aflatoxin Status</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* Preservative */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-300">Preservative</label>
          <select
            name="preservative"
            value={columnValues.preservative}
            onChange={handleColumnValueChange}
            className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
          >
            <option value="">Select Preservative Status</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* Antibiotic */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-300">Antibiotic</label>
          <select
            name="antibiotic"
            value={columnValues.antibiotic}
            onChange={handleColumnValueChange}
            className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
          >
            <option value="">Select Antibiotic Status</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* GRAS */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-300">GRAS</label>
          <select
            name="gras"
            value={columnValues.gras}
            onChange={handleColumnValueChange}
            className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
          >
            <option value="">Select GRAS Status</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* Prop 65 Compliant */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-300">
            Proposition 65 Compliant
          </label>
          <select
            name="prop65_complaint"
            value={columnValues.prop65_complaint}
            onChange={handleColumnValueChange}
            className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200"
          >
            <option value="">Select Proposition 65 Compliant Status</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Declaration;
