import React from 'react';

const AllergenSelection = ({allergens, handleAllergenChange}) => {
    const allergenList = [
        'wheat', 'eggs', 'crustaceans_shell_fish', 'fish', 'milk', 'tree_nuts',
        'peanuts', 'soy', 'sesame_seeds', 'celery', 'barley_oats_rye_spelt',
        'orange_kiwi_peaches_apples', 'mushrooms', 'mustard', 'lupin', 'mulluscs', 'sulfur'
    ];

    return (
        <div>
            <h2 className="text-xl font-bold mb-4">Allergen Selection</h2>
            <form className="space-y-6">
                {allergenList.map((allergen) => (
                    <div key={allergen} className="flex items-center space-x-4 mb-2">
                        <label className="font-semibold capitalize w-1/3">{allergen.replace(/_/g, ' ')}</label>
                        <select
                            name={allergen}
                            value={allergens[allergen]}
                            onChange={handleAllergenChange}
                            className="border border-gray-600 rounded px-4 py-2 bg-gray-700 text-gray-200 w-1/3"
                        >
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                ))}
            </form>
        </div>
    );
};

export default AllergenSelection;
