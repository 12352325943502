import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { toast } from 'react-toastify';
import { COGNITO_CONFIG } from './cognitoconfig';
import background from '../../img/background3.jpg';

const EmailVerification = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const backgroundImageStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(${background})`,
    backgroundSize: `cover`,
  };

  // Retrieve the email from location state
  const location = useLocation();
  const email = location.state?.email;

  if (!email) {
    toast.error('No email provided for verification.');
    navigate('/signup'); // Redirect back to signup if email is not available
    return null;
  }

  const poolData = {
    UserPoolId: COGNITO_CONFIG.UserPoolId,
    ClientId: COGNITO_CONFIG.ClientId,
  };

  const userPool = new CognitoUserPool(poolData);

  const userData = {
    Username: email,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  const handleVerifyCode = () => {
    cognitoUser.confirmRegistration(verificationCode, true, (err, result) => {
      if (err) {
        console.error('Error verifying code:', err);
        setError(err.message || 'Error verifying code. Please try again.');
        toast.error('Verification failed. Please try again.');
        return;
      }
      console.log('Verification successful:', result);
      toast.success('Verification successful! You can now log in.');
      setError('');
      navigate('/'); // Redirect to login page on successful verification
    });
  };

  return (
    <div className="min-h-screen flex items-center justify-center" style={backgroundImageStyle}>
      <div className="bg-black p-8 space-y-5 max-w-lg w-full rounded-lg">
        <h1 className="text-center text-white text-xl">Email Verification</h1>
        <input
          type="text"
          className="border-0 border-b-2 w-full border-gray-500 bg-transparent placeholder:text-gray-500 focus:outline-none focus:border-gray-200 text-white"
          placeholder="Verification Code"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          required
        />
        {error && <p className="text-red-500">{error}</p>}
        <button
          onClick={handleVerifyCode}
          className="text-white border border-white p-2 w-full hover:bg-white hover:text-black transition ease-in-out duration-500"
        >
          Verify Code
        </button>
      </div>
    </div>
  );
};

export default EmailVerification;
