import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa"; // Removed FaFilter import
import { useNavigate } from "react-router-dom";

const ProductDatabase = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();


  useEffect(() => {
    // Fetch products from API
    const fetchProducts = async () => {
      setLoading(true); // Set loading to true before fetching
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URI}/elixir/fetch_product`, {
          method: "GET",
          headers: {
            accept: "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("API Response:", data);

        // Adjust this line to access the products array correctly
        if (data && Array.isArray(data.data)) {
          setProducts(data.data); // Access the products from data.data
          setFilteredProducts(data.data); // Also set filtered products
        } else {
          console.error("API response is not in expected format:", data);
          setProducts([]);
          setFilteredProducts([]);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
        setError("Failed to load products");
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchProducts(); // Call the fetch function
  }, []);

  const handledbform = () => {
    navigate("/productdbform");
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue);
    const filtered = products.filter(
      (product) =>
        product.product_name.toLowerCase().includes(searchValue) ||
        product.product_id.toLowerCase().includes(searchValue)
    );
    setFilteredProducts(filtered);
  };

  const handleProductClick = (product_id) => {
    navigate(`/productitem/${product_id}`);
  };

  return (
    <div className="min-h-screen bg-slate-700">
      <div className="flex justify-between items-center p-4">
        <div className="flex-1">
          <div className="relative w-full md:w-1/2 lg:w-1/2">
            <FaSearch className="absolute left-3 top-3 text-white" />
            <input
              type="text"
              placeholder="Search by product name or item code."
              value={searchTerm}
              onChange={handleSearch}
              className="pl-10 bg-gray-900 text-white rounded w-full px-4 py-2"
            />
          </div>
        </div>

        <button
          className="ml-auto bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          onClick={handledbform}
        >
          + Add Product
        </button>
      </div>

      {loading && <p className="text-white text-center">Loading products...</p>}
      {error && <p className="text-red-500 text-center">{error}</p>}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
        {filteredProducts.length > 0
          ? filteredProducts.map((product) => (
            <div
              key={product.product_id} // Using product ID for key
              className="bg-gray-800 text-white p-4 rounded shadow-md cursor-pointer"
              onClick={() => handleProductClick(product.product_id)}
            >
              <h3 className="text-lg font-bold mb-2">
                {product.product_name}
              </h3>
              <p className="text-sm">Product ID: {product.product_id}</p>
            </div>
          ))
          : !loading && (
            <p className="text-white text-center">No products found.</p>
          )}
      </div>
    </div>
  );
};

export default ProductDatabase;
