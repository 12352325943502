import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { COGNITO_CONFIG } from './cognitoconfig';
import background from '../../img/background3.jpg';

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [step, setStep] = useState(1); // Step 1: Enter email, Step 2: Enter code and new password
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    const poolData = {
        UserPoolId: COGNITO_CONFIG.UserPoolId,
        ClientId: COGNITO_CONFIG.ClientId
    };

    const userPool = new CognitoUserPool(poolData);

    const handleForgotPassword = (e) => {
        e.preventDefault();

        const user = new CognitoUser({
            Username: email,
            Pool: userPool
        });

        user.forgotPassword({
            onSuccess: (data) => {
                console.log("Code sent: ", data);
                setStep(2);
                setMessage("Verification code sent to your email.");
            },
            onFailure: (err) => {
                console.error("onFailure: ", err);
                setError(err.message || "Something went wrong. Please try again.");
            }
        });
    };

    const handleResetPassword = (e) => {
        e.preventDefault();

        const user = new CognitoUser({
            Username: email,
            Pool: userPool
        });

        user.confirmPassword(verificationCode, newPassword, {
            onSuccess: () => {
                console.log("Password reset successful");
                setMessage("Password has been reset successfully. You can now log in with your new password.");
                setTimeout(() => navigate('/'), 2000); // Redirect to login page after 2 seconds
            },
            onFailure: (err) => {
                console.error("onFailure: ", err);
                setError(err.message || "Failed to reset the password. Please try again.");
            }
        });
    };

    const backgroundImageStyle = {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(${background})`,
        backgroundSize: `cover`,
    };

    return (
        <div
            className="min-h-screen flex items-center justify-center"
            style={backgroundImageStyle}
        >
            <div className="bg-black p-10 space-y-6 max-w-lg w-full rounded-lg shadow-lg">
                <h1 className="text-center text-white text-2xl font-bold">Forgot Password</h1>
                {error && <div className="text-red-500 text-center">{error}</div>}
                {message && <div className="text-green-500 text-center">{message}</div>}

                {step === 1 && (
                    <form onSubmit={handleForgotPassword} className='space-y-10'>
                        <div className="relative">
                            <input
                                type="text"
                                className="border-0 border-b-2 w-full border-gray-500 bg-transparent placeholder:text-gray-500 focus:outline-none focus:border-gray-300 text-white py-3 px-4 rounded-md"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <button
                            type="submit"
                            className="text-white border border-white p-3 w-full rounded-md hover:bg-white hover:text-black transition ease-in-out duration-300"
                        >
                            Send Verification Code
                        </button>
                    </form>
                )}

                {step === 2 && (
                    <form onSubmit={handleResetPassword} className='space-y-10'>
                        <div className="relative">
                            <input
                                type="text"
                                className="border-0 border-b-2 w-full border-gray-500 bg-transparent placeholder:text-gray-500 focus:outline-none focus:border-gray-300 text-white py-3 px-4 rounded-md"
                                placeholder="Verification Code"
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                            />
                        </div>
                        <div className="relative">
                            <input
                                type="password"
                                className="border-0 border-b-2 w-full border-gray-500 bg-transparent placeholder:text-gray-500 focus:outline-none focus:border-gray-300 text-white py-3 px-4 rounded-md"
                                placeholder="New Password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </div>
                        <button
                            type="submit"
                            className="text-white border border-white p-3 w-full rounded-md hover:bg-white hover:text-black transition ease-in-out duration-300"
                        >
                            Reset Password
                        </button>
                    </form>
                )}
                <div className="flex justify-center">
                    <Link className="text-gray-400 hover:text-white transition ease-in-out duration-300" to='/'>
                        Back to Login
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;