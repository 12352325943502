import React, { useState } from 'react';
import background from '../../img/background3.jpg';
import { Link, useNavigate } from 'react-router-dom';
import { CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { toast } from 'react-toastify';
import { COGNITO_CONFIG } from '../Services/cognitoconfig';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import icons

const UserSignup = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to toggle confirm password visibility
  const [role, setRole] = useState('User'); // State for role selection
  const navigate = useNavigate(); // Initialize useNavigate

  const backgroundImageStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(${background})`,
    backgroundSize: `cover`,
  };

  const poolData = {
    UserPoolId: COGNITO_CONFIG.UserPoolId,
    ClientId: COGNITO_CONFIG.ClientId
  };

  const userPool = new CognitoUserPool(poolData);

  const handleSignup = () => {
    // Reset error message
    setError('');

    // Validate input fields
    if (!email || !password || !confirmPassword) {
      setError("All fields are required.");
      return;
    }

    // Validate password pattern
    const passwordPattern = /^[\S]+.*[\S]+$/;
    if (!passwordPattern.test(password)) {
      setError("Password must start and end with a non-whitespace character.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    const attributeList = [
      new CognitoUserAttribute({ Name: 'email', Value: email }),
    ];

    userPool.signUp(email, password, attributeList, null, (err, data) => {
      if (err) {
        console.error('Error signing up:', err);
        // Display a generic error message instead of Cognito's errors
        setError('Signup failed. Please check your input and try again.');
        toast.error('Signup failed. Please try again.');
        return;
      }
      console.log('Signup successful:', data);
      toast.success('Signup successful! Please check your email to verify your account.');
      navigate('/email', { state: { email, role } }); // Pass role in navigation
    });
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center"
      style={backgroundImageStyle}
    >
      <div className="bg-black p-10 space-y-6 max-w-lg w-full rounded-lg shadow-lg">
        <h1 className="text-center text-white text-2xl font-bold">User Signup</h1>
        <div className="relative">
          <input
            type="email"
            className="border-0 border-b-2 w-full border-gray-500 bg-transparent placeholder:text-gray-500 focus:outline-none focus:border-gray-300 text-white py-3 px-4 rounded-md"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="relative">
          <input
            type={showPassword ? 'text' : 'password'}
            className="border-0 border-b-2 w-full border-gray-500 bg-transparent placeholder:text-gray-500 focus:outline-none focus:border-gray-300 text-white py-3 px-4 rounded-md pr-10"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <span
            className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <FaEyeSlash className="text-white" /> : <FaEye className="text-white" />}
          </span>
        </div>
        <div className="relative">
          <input
            type={showConfirmPassword ? 'text' : 'password'}
            className="border-0 border-b-2 w-full border-gray-500 bg-transparent placeholder:text-gray-500 focus:outline-none focus:border-gray-300 text-white py-3 px-4 rounded-md pr-10"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <span
            className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          >
            {showConfirmPassword ? <FaEyeSlash className="text-white" /> : <FaEye className="text-white" />}
          </span>
        </div>
        <div className="relative">
          <select
            className="border-0 border-b-2 w-full border-gray-500 bg-black text-white py-3 px-4 rounded-md focus:outline-none focus:border-gray-300"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          >
            <option value="User">User</option>
            <option value="Admin">Admin</option>
          </select>
        </div>
        {error && <p className="text-red-500 text-center">{error}</p>}
        <button
          onClick={handleSignup}
          className="text-white border border-white p-3 w-full rounded-md hover:bg-white hover:text-black transition ease-in-out duration-300"
        >
          Submit
        </button>
        <hr className='border border-white w-full' />
        <div className="flex justify-center">
          <Link className='text-gray-400 hover:text-white transition ease-in-out duration-300' to='/'>
            Already have an account? Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserSignup;