import React from 'react';

const Attributes = ({attributeDetails, handleAttributeDetailsChange}) => {
    return (
        <div className="flex flex-col items-center">
            <div className="w-full max-w-2xl my-7 space-y-3">
                <h2 className="text-xl font-semibold text-center">Ingredient Details</h2>
                <table className="min-w-full table-auto bg-gray-700 text-gray-200 rounded-lg text-center">
                    <thead>
                    <tr>
                        <th className="px-4 py-2 text-center">Item Attributes</th>
                        <th className="px-4 py-2 text-center">Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.keys(attributeDetails).map((attributeName, index) => (
                        <tr key={index} className="border-t border-gray-600 text-center">
                            <td className="px-4 py-2 capitalize text-center">{attributeName}</td>
                            <td className="px-4 py-2 text-center">
                                <input
                                    type="text"
                                    name={attributeName}
                                    value={attributeDetails[attributeName]}
                                    onChange={handleAttributeDetailsChange}
                                    className="border border-gray-600 rounded px-4 py-2 bg-gray-600 text-gray-200 w-72 text-center"
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Attributes;
