import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import UserLogin from "./Components/Services/UserLogin";
import UserSignup from "./Components/Services/UserSignup";
import Download from "./Components/Download";
import PrivateRoute from "./Components/PrivateRoute";
import EmailVerification from "./Components/Services/EmailVerification";
import Forget from "./Components/Services/Forget";
import Database from "./Components/Database/Database";
import ProductDBForm from "./Components/Database/ProductDBForm";
import IngredientDBForm from "./Components/Database/IngredientForm/IngredientDBForm";
import Productitem from "./Components/Database/Productitem";
import NewPassword from "./Components/Services/NewPassword";
import IngredientItem from "./Components/Database/IngredientForm/IngredientItem";
import NotFoundPage from "./Components/Services/Notfound";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<UserLogin />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/forget" element={<Forget />} />
        <Route
          path="/signup"
          element={<PrivateRoute component={UserSignup} requiredRole="ADMIN" />}
        />
        <Route path="/email" element={<EmailVerification />} />
        <Route
          path="/database"
          element={<PrivateRoute component={Database} requiredRole="ADMIN" />}
        />
        <Route
          path="/productdbform"
          element={
            <PrivateRoute component={ProductDBForm} requiredRole="ADMIN" />
          }
        />
        <Route
          path="/download"
          element={<PrivateRoute component={Download} requiredRole="USER" />}
        />
        <Route
          path="/ingredientdb"
          element={
            <PrivateRoute component={IngredientDBForm} requiredRole="ADMIN" />
          }
        />
        <Route
          path="/productitem/:product_id"
          element={
            <PrivateRoute component={Productitem} requiredRole="ADMIN" />
          }
        />
        <Route
          path="/ingredient/:ing_item_code"
          element={
            <PrivateRoute component={IngredientItem} requiredRole="ADMIN" />
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
