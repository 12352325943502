import React, {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {CognitoUser, CognitoUserPool} from 'amazon-cognito-identity-js';
import {COGNITO_CONFIG} from '../../Components/Services/cognitoconfig';

const NewPassword = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {username, session} = location.state || {}; // Ensure session is passed in state

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!username || !session) {
            navigate('/');
        }
    }, [username, session, navigate]);

    const userPool = new CognitoUserPool({
        UserPoolId: COGNITO_CONFIG.UserPoolId,
        ClientId: COGNITO_CONFIG.ClientId,
    });

    // Create the CognitoUser instance
    const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            setLoading(false);
            return;
        }

        // Pass the session token along with the password
        cognitoUser.completeNewPasswordChallenge(password, [], {
            onSuccess: (result) => {
                console.log('Password updated successfully:', result);
                navigate('/'); // Redirect to the desired page after password update
                setLoading(false);
            },
            onFailure: (err) => {
                console.error('Error updating password:', err);
                setError(err.message);
                setLoading(false);
            },
        });
    };

    return (
        <div className="min-h-screen flex items-center justify-center">
            <div
                className="bg-black p-10 space-y-6 max-w-lg w-full rounded-lg shadow-lg"
                style={{background: 'rgba(0, 0, 0, 0.7)'}}
            >
                <h1 className="text-center text-white text-2xl font-bold">New Password</h1>
                {username && (
                    <form onSubmit={handleSubmit}>
                        <div className="relative mt-4">
                            <input
                                type="password"
                                className="border-0 border-b-2 w-full border-gray-500 bg-transparent placeholder:text-gray-500 focus:outline-none focus:border-gray-300 text-white py-3 px-4 rounded-md"
                                placeholder="New Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className="relative mt-4">
                            <input
                                type="password"
                                className="border-0 border-b-2 w-full border-gray-500 bg-transparent placeholder:text-gray-500 focus:outline-none focus:border-gray-300 text-white py-3 px-4 rounded-md"
                                placeholder="Confirm New Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </div>
                        {error && <p className="text-red-600">{error}</p>}
                        <button
                            type="submit"
                            className="mt-6 text-white border border-white p-3 w-full rounded-md hover:bg-white hover:text-black transition ease-in-out duration-300"
                            disabled={loading}
                        >
                            {loading ? 'Updating password...' : 'Update Password'}
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default NewPassword;
