import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaTrash, FaEdit, FaSave } from "react-icons/fa"; // Import FaTrash and FaEdit icons

const IngredientItem = () => {
  const { ing_item_code } = useParams();
  const [ingredients, setIngredients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false); // Track edit mode
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchIngredientDetails = useCallback(async () => {
    if (!ing_item_code) {
      setError("Ingredient code is missing.");
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URI}/elixir/fetch_ingredient/${ing_item_code}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const { data } = await response.json();
      console.log("Full API Response:", data);

      if (data && data.length > 0) {
        setIngredients(data);
        setError(null);
      } else {
        setError("No data found for this ingredient.");
      }
    } catch (error) {
      setError("Error fetching ingredient details: " + error.message);
    } finally {
      setLoading(false);
    }
  }, [ing_item_code]);

  const deleteIngredient = async (rmCode) => {
    if (!ing_item_code || !rmCode) return;

    const confirmDelete = window.confirm(
      "Are you sure you want to delete this ingredient?"
    );
    if (!confirmDelete) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URI}/elixir/ingredient/${ing_item_code}?rm_code=${rmCode}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete ingredient.");
      }

      setIngredients((prevIngredients) =>
        prevIngredients.filter((ingredient) => ingredient.rm_code !== rmCode)
      );

      alert("Ingredient deleted successfully.");

      // Redirect only if all rows are deleted
      setIngredients((prev) => {
        if (prev.length === 0) {
          navigate("/database");
        }
        return prev;
      });
    } catch (error) {
      setError("Error deleting ingredient: " + error.message);
    }
  };

  const handleEdit = () => {
    setEditMode(true); // Enable edit mode
  };

  const handleSave = async () => {
    try {
      // Prepare the data to be sent in the PATCH request
      const payload = {
        ing_item_code: ingredients[0].ing_item_code, // Assuming you have this in the first ingredient
        ing_name: ingredients[0].ing_name,
        vendor: ingredients[0].vendor,
        rm_code: ingredients[0].rm_code,
        cas_num: ingredients[0].cas_num,
        ec_num: ingredients[0].ec_num,
        ing_type: ingredients[0].ing_type,
        source_type: ingredients[0].source_type,
        source: ingredients[0].source,
        country_origin: ingredients[0].country_origin,
        created_at: ingredients[0].created_at,
        updated_at: ingredients[0].updated_at,
      };

      // Sending PATCH request to update the ingredient
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URI}/elixir/ingredient/${ing_item_code}?rm_code=${ingredients[0].rm_code}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update ingredient details.");
      }

      alert("Ingredient details updated successfully.");
      setEditMode(false); // Disable edit mode after successful save
    } catch (error) {
      setError("Error saving ingredient details: " + error.message);
    }
  };

  const handleInputChange = (key, value) => {
    setIngredients((prev) => {
      const updatedIngredients = [...prev];
      updatedIngredients[0][key] = value; // Update only the first ingredient (or adjust as needed)
      return updatedIngredients;
    });
  };

  const handleVendorInputChange = (index, value) => {
    setIngredients((prev) => {
      const updatedIngredients = [...prev];
      updatedIngredients[index].country_origin = value; // Update the country_origin of the vendor
      return updatedIngredients;
    });
  };

  // const formatDate = (dateString) => {
  //   if (!dateString) return "N/A";
  //   const date = new Date(dateString);
  //   if (isNaN(date.getTime())) return "N/A";
  //   return date.toLocaleDateString("en-IN");
  // };

  const formatToIST = (dateString) => {
    if (!dateString) return "N/A";

    const date = new Date(dateString); // Parse the GMT date
    const istOffset = 5.5 * 60 * 60 * 1000; // IST is +5:30 from GMT in milliseconds
    const istDate = new Date(date.getTime() + istOffset);

    // Helper to get ordinal suffix
    const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return "th"; // Covers 11th to 19th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const day = istDate.getDate();
    const month = istDate.toLocaleString("en-IN", { month: "short" }); // Get short month name
    const year = istDate.getFullYear();
    const time = istDate.toLocaleString("en-IN", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // 12-hour format with AM/PM
    });

    return `${day}${getOrdinalSuffix(day)} ${month} ${year}, ${time}`;
  };

  useEffect(() => {
    fetchIngredientDetails();
  }, [fetchIngredientDetails]);

  return (
    <div className="min-h-screen bg-slate-700 p-4 text-white flex items-center justify-center relative">
      <button
        onClick={() => navigate(-1)}
        className="absolute top-4 left-4 bg-gray-800 text-white py-2 px-4 rounded shadow hover:bg-gray-600"
      >
        Back
      </button>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div className="text-red-500">{error}</div>
      ) : (
        <div className="bg-gray-800 rounded-lg shadow-lg p-6 w-full max-w-4xl">
          {ingredients.length > 0 && (
            <>
              <div className="bg-gray-700 rounded-lg shadow-md p-4 mb-4">
                <h2 className="text-lg font-semibold">
                  {ingredients[0].ing_name || "N/A"}
                </h2>
                <p>
                  <strong>Ingredient Item Code:</strong>
                  {ingredients[0].ing_item_code || "N/A"}
                </p>
                <p>
                  <strong>CAS Number:</strong>
                  {editMode ? (
                    <input
                      type="text"
                      value={ingredients[0].cas_num} // Correctly referencing the first ingredient
                      onChange={(e) =>
                        handleInputChange("cas_num", e.target.value)
                      }
                      className="bg-gray-600 text-white p-2 rounded w-full"
                    />
                  ) : (
                    ingredients[0].cas_num || "N/A"
                  )}
                </p>
                <p>
                  <strong>EC Number:</strong>
                  {editMode ? (
                    <input
                      type="text"
                      value={ingredients[0].ec_num}
                      onChange={(e) =>
                        handleInputChange("ec_num", e.target.value)
                      }
                      className="bg-gray-600 text-white p-2 rounded w-full"
                    />
                  ) : (
                    ingredients[0].ec_num || "N/A"
                  )}
                </p>
                <p>
                  <strong>Ingredient Type:</strong>
                  {editMode ? (
                    <input
                      type="text"
                      value={ingredients[0].ing_type}
                      onChange={(e) =>
                        handleInputChange("ing_type", e.target.value)
                      }
                      className="bg-gray-600 text-white p-2 rounded w-full"
                    />
                  ) : (
                    ingredients[0].ing_type || "N/A"
                  )}
                </p>
                <p>
                  <strong>Source Type:</strong>
                  {editMode ? (
                    <input
                      type="text"
                      value={ingredients[0].source_type}
                      onChange={(e) =>
                        handleInputChange("source_type", e.target.value)
                      }
                      className="bg-gray-600 text-white p-2 rounded w-full"
                    />
                  ) : (
                    ingredients[0].source_type || "N/A"
                  )}
                </p>
                <p>
                  <strong>Source:</strong>
                  {editMode ? (
                    <input
                      type="text"
                      value={ingredients[0].source}
                      onChange={(e) =>
                        handleInputChange("source", e.target.value)
                      }
                      className="bg-gray-600 text-white p-2 rounded w-full"
                    />
                  ) : (
                    ingredients[0].source || "N/A"
                  )}
                </p>
                <p>
                  <strong>Created at:</strong>{" "}
                  {formatToIST(ingredients[0].created_at)}
                </p>
                <p>
                  <strong>Updated at:</strong>{" "}
                  {formatToIST(ingredients[0].updated_at)}
                </p>
              </div>

              <h1 className="text-2xl mb-4">
                Vendors for {ingredients[0]?.ing_name || "No Name Available"}
              </h1>
              <div className="mb-6">
                <h2 className="text-xl mb-2">Vendors</h2>
                <table className="min-w-full border-separate border border-gray-600">
                  <thead>
                    <tr>
                      <th className="border border-gray-500 p-2">Vendor</th>
                      <th className="border border-gray-500 p-2">RM Code</th>
                      <th className="border border-gray-500 p-2">
                        Country Origin
                      </th>
                      <th className="border border-gray-500 p-2">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ingredients.map((ingredient, index) => (
                      <tr key={index} className="hover:bg-gray-700 text-center">
                        <td className="border border-gray-500 p-2">
                          {ingredient.vendor || "Vendor Unknown"}
                        </td>
                        <td className="border border-gray-500 p-2">
                          {ingredient.rm_code || "N/A"}
                        </td>
                        <td className="border border-gray-500 px-40 py-2">
                          {editMode ? (
                            <input
                              type="text"
                              value={ingredient.country_origin}
                              onChange={(e) =>
                                handleVendorInputChange(index, e.target.value)
                              }
                              className="w-full text-center bg-gray-600 p-2 rounded text-white"
                            />
                          ) : (
                            ingredient.country_origin || "N/A"
                          )}
                        </td>
                        <td className="border border-gray-500 p-2">
                          <button
                            onClick={() => deleteIngredient(ingredient.rm_code)}
                            className="bg-red-600 text-white py-1 px-3 rounded hover:bg-red-500 flex items-center justify-center mx-auto"
                          >
                            <FaTrash className="mr-1" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Single Edit Button */}
              <div className="mt-4 text-right">
                {!editMode ? (
                  <button
                    onClick={handleEdit}
                    className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-500 flex items-center justify-center mx-auto"
                  >
                    <FaEdit className="mr-2" /> Edit
                  </button>
                ) : (
                  <button
                    onClick={handleSave}
                    className="bg-green-600 text-white py-2 px-4 rounded hover:bg-blue-500 flex items-center justify-center mx-auto"
                  >
                    <FaSave className="mr-2" /> Save
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default IngredientItem;